import type { TFunction } from '@wix/yoshi-flow-editor';
import type {
  ModifierGroupListItem,
  ModifierListItem,
  ModifierRepeaterData,
  ModifierGroupRule,
  RuleTpa,
} from 'root/types/modifiers';
import { RuleType } from 'root/types/modifiers';

interface ruleUtilsArgs {
  mandatory: boolean;
  minSelections: number;
  maxSelections?: number | null;
}
export const isSingleSelectRule = (rule: ModifierGroupRule | RuleTpa) =>
  rule.mandatory && rule.minSelections === 1 && rule.maxSelections === 1;

export const hasNoLimit = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  !mandatory && minSelections < 1 && (maxSelections ? maxSelections < 1 : true);

export const canChooseOne = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  !mandatory && minSelections === 0 && (maxSelections ? maxSelections === 1 : false);

export const hasToChooseOne = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory && minSelections === 1 && (maxSelections ? maxSelections === 1 : false);

export const hasToChooseX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory ? maxSelections && minSelections === maxSelections : false;

export const hasToChooseAtLeastOne = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory ? minSelections === 1 && maxSelections === null : false;

export const hasToChooseAtLeastX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  mandatory ? minSelections > 1 && maxSelections === null : false;

export const chooseUpToX = ({ mandatory, minSelections, maxSelections }: ruleUtilsArgs) =>
  !mandatory && minSelections === 0 && (maxSelections ? maxSelections > 1 : false);

export const hasToChooseBetweenXAndY = ({
  mandatory,
  minSelections,
  maxSelections,
}: ruleUtilsArgs) =>
  mandatory ? (maxSelections ? minSelections > 0 && maxSelections > minSelections : false) : false;

export const getModifierGroupRuleType = (modifierGroupRule: ModifierGroupRule) => {
  const mandatory = modifierGroupRule.mandatory ?? false;
  const minSelections = modifierGroupRule.minSelections ?? 0;
  const maxSelections = modifierGroupRule.maxSelections;
  const ruleFields = { mandatory, minSelections, maxSelections };
  if (hasNoLimit(ruleFields)) {
    return RuleType.NO_LIMIT;
  }
  if (hasToChooseOne(ruleFields) || canChooseOne(ruleFields)) {
    return RuleType.CHOOSE_ONE;
  }
  if (hasToChooseX(ruleFields)) {
    return RuleType.CHOOSE_X;
  }
  if (hasToChooseAtLeastOne(ruleFields)) {
    return RuleType.CHOOSE_AT_LEAST_ONE;
  }
  if (hasToChooseAtLeastX(ruleFields)) {
    return RuleType.CHOOSE_AT_LEAST_X;
  }
  if (chooseUpToX(ruleFields)) {
    return RuleType.CHOOSE_UP_TO_X;
  }
  if (hasToChooseBetweenXAndY(ruleFields)) {
    return RuleType.CHOOSE_BETWEEN_X_AND_Y;
  }
  return RuleType.NO_LIMIT;
};

export const getModifierGroupLabel = (t: TFunction, modifierRepeaterData: ModifierRepeaterData) => {
  const modifierGroupName = modifierRepeaterData.name ?? '';
  const modifierGroupRule = modifierRepeaterData.rule ?? {};
  const minSelections = modifierGroupRule.minSelections ?? 0;
  const maxSelections = modifierGroupRule.maxSelections;
  const type = getModifierGroupRuleType(modifierRepeaterData?.rule ?? {});
  switch (type) {
    case RuleType.NO_LIMIT:
      return modifierGroupName;
    case RuleType.CHOOSE_ONE:
      return t('itemModal.modifierGroup-label.choose-one', {
        modifierGroupName,
      });
    case RuleType.CHOOSE_X:
      return t('itemModal.modifierGroup-label.choose-x', {
        modifierGroupName,
        x: minSelections,
      });
    case RuleType.CHOOSE_AT_LEAST_ONE:
      return t('itemModal.modifierGroup-label.choose-at-least-one', { modifierGroupName });
    case RuleType.CHOOSE_AT_LEAST_X:
      return t('itemModal.modifierGroup-label.choose-at-least-x', {
        modifierGroupName,
        x: minSelections,
      });
    case RuleType.CHOOSE_UP_TO_X:
      return t('itemModal.modifierGroup-label.choose-up-to-x', {
        modifierGroupName,
        x: maxSelections,
      });
    case RuleType.CHOOSE_BETWEEN_X_AND_Y:
      return t('itemModal.modifierGroup-label.choose-between-x-and-y', {
        modifierGroupName,
        x: minSelections,
        y: maxSelections,
      });
    default:
      return modifierGroupName;
  }
};

export const getModifierGroupErrorText = (
  t: TFunction,
  modifierRepeaterData: ModifierRepeaterData
) => {
  const modifierGroupRule: ModifierGroupRule = modifierRepeaterData?.rule ?? {};
  const minSelections = modifierGroupRule.minSelections ?? 0;
  const maxSelections = modifierGroupRule.maxSelections;
  const type = getModifierGroupRuleType(modifierGroupRule);
  switch (type) {
    case RuleType.CHOOSE_ONE:
      return t('itemModal.modifierGroup-error.choose-one');
    case RuleType.CHOOSE_X:
      return t('itemModal.modifierGroup-error.choose-x', { x: minSelections });
    case RuleType.CHOOSE_AT_LEAST_ONE:
      return t('itemModal.modifierGroup-error.choose-at-least-one');
    case RuleType.CHOOSE_AT_LEAST_X:
      return t('itemModal.modifierGroup-error.choose-at-least-x', { x: minSelections });
    case RuleType.CHOOSE_UP_TO_X:
      return t('itemModal.modifierGroup-error.choose-up-to-x', { x: maxSelections });
    case RuleType.CHOOSE_BETWEEN_X_AND_Y:
      return t('itemModal.modifierGroup-error.choose-between-x-and-y', {
        x: minSelections,
        y: maxSelections,
      });
    default:
      return '';
  }
};

export const isSelectedModifierGroupsValid = (
  selectedModifiers: Record<string, ModifierListItem[]>,
  allModifierGroups: ModifierGroupListItem[]
) => {
  const errorsState: Record<string, boolean> = {};
  let isValid = true;

  for (const modifierGroup of allModifierGroups) {
    const modifierGroupSelectionCount = selectedModifiers[modifierGroup.id]?.length ?? 0;
    const modifierGroupRule = modifierGroup?.rule ?? {};
    const isModifierGroupValid = checkIsModifierGroupValid(
      modifierGroupSelectionCount,
      modifierGroupRule
    );

    if (!isModifierGroupValid) {
      errorsState[modifierGroup.id] = true;
      isValid = false;
    } else {
      errorsState[modifierGroup.id] = false;
    }
  }

  return { isValid, errorsState };
};

export const checkIsModifierGroupValid = (
  modifierGroupSelectionCount: number,
  modifierGroupRule: ModifierGroupRule
) => {
  const minSelections = modifierGroupRule.minSelections ?? 0;
  const maxSelections = modifierGroupRule.maxSelections;
  const isLessThanMinSelections = modifierGroupSelectionCount < minSelections;
  const isGreaterThanMaxSelections = maxSelections
    ? modifierGroupSelectionCount > maxSelections
    : false;

  if (isLessThanMinSelections || isGreaterThanMaxSelections) {
    return false;
  }

  return true;
};

export const getPreSelectedModifiers = (modifiers: ModifierListItem[]): ModifierListItem[] =>
  modifiers.reduce<ModifierListItem[]>((acc, modifier) => {
    if (modifier.isPreSelected) {
      return [...acc, modifier];
    }

    return acc;
  }, []);

export const getFirstPreSelectedModifier = (modifiers: ModifierListItem[]) =>
  modifiers.find(({ isPreSelected }) => isPreSelected);
