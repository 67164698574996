import type { IHttpClient } from '@wix/yoshi-flow-editor';
import { getItem, queryItems } from '@wix/ambassador-restaurants-menus-v1-item/http';
import type {
  CursorQuery,
  QueryItemsResponse,
} from '@wix/ambassador-restaurants-menus-v1-item/types';
import { PopulatedItemClientFactory } from '@wix/restaurants-populated-item-client/web';
import type { ItemTpa } from 'root/types/item';

type FileInfoType = Partial<{
  width: number;
  height: number;
  file_name: string;
}>;

type ItemQueryResponse = {
  items: Required<QueryItemsResponse['items']>;
  next: (() => Promise<ItemQueryResponse>) | undefined;
};

export class ItemsClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  private async query(
    filter: CursorQuery['filter'] | undefined = undefined,
    cursor: string | null = null
  ): Promise<ItemQueryResponse> {
    const response = await this.httpClient.request(
      queryItems({
        query: {
          filter,
          cursorPaging: {
            limit: 500,
            cursor,
          },
        },
      })
    );
    const { pagingMetadata, items } = response.data;
    return {
      items: items || [],
      next: pagingMetadata?.hasNext
        ? this.query.bind(this, undefined, pagingMetadata.cursors?.next)
        : undefined,
    };
  }

  fetchAllItems = async () => {
    try {
      let response = await this.query({ visible: { $ne: false } });
      const items = [...response.items!];
      while (response.next) {
        response = await response.next();
        items.push(...response.items!);
      }
      return { items };
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getItemById = async (itemId: string) => {
    try {
      return this.httpClient.request(getItem({ itemId })).then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getImgsData = async (
    fileIds: string[],
    metaSiteId: string
  ): Promise<{ id: string; width: number; height: number }[]> => {
    try {
      const url = `https://files.wix.com/site/media/files/multi/info`;

      return this.httpClient
        .post(url, {
          files: fileIds,
          site_id: metaSiteId,
        })
        .then((response) => {
          const { files = [] } = response.data as Partial<{
            files: FileInfoType[];
          }>;

          return files.map((file: FileInfoType) => {
            const { file_name = '', width = 0, height = 0 } = file;
            return { id: file_name, width, height };
          });
        });
    } catch (e: unknown) {
      console.error(e);
      return [];
    }
  };
}

export class PopulatedItemsClient {
  httpClient;
  populatedItemsClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.populatedItemsClient = PopulatedItemClientFactory({
      httpClient,
      populationLevel: {
        labels: true,
        modifierGroups: false,
        modifiers: false,
        priceVariants: false,
      },
    });
  }

  private async query(
    filter: CursorQuery['filter'] | undefined = undefined,
    cursor: string | null = null
  ): Promise<ItemQueryResponse> {
    const response = await this.populatedItemsClient.query({
      filter,
      cursorPaging: {
        limit: 500,
        cursor,
      },
    });
    if (response.hasError) {
      throw response.error;
    }
    const { pagingMetadata, data } = response.data;
    return {
      items: (data || []) as ItemTpa[],
      next: pagingMetadata?.hasNext
        ? this.query.bind(this, undefined, pagingMetadata.cursors?.next)
        : undefined,
    };
  }

  fetchAllItems = async () => {
    try {
      let response = await this.query({ visible: { $ne: false } });
      const items = [...response.items!];
      while (response.next) {
        response = await response.next();
        items.push(...response.items!);
      }
      return { items };
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getItemById = async (itemId: string) => {
    try {
      return this.populatedItemsClient.getById(itemId).then((response) => response);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getImgsData = async (
    fileIds: string[],
    metaSiteId: string
  ): Promise<{ id: string; width: number; height: number }[]> => {
    try {
      const url = `https://files.wix.com/site/media/files/multi/info`;

      return this.httpClient
        .post(url, {
          files: fileIds,
          site_id: metaSiteId,
        })
        .then((response) => {
          const { files = [] } = response.data as Partial<{
            files: FileInfoType[];
          }>;

          return files.map((file: FileInfoType) => {
            const { file_name = '', width = 0, height = 0 } = file;
            return { id: file_name, width, height };
          });
        });
    } catch (e: unknown) {
      console.error(e);
      return [];
    }
  };
}
