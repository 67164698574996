import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  convertModifierGroupToModifierGroupType,
  convertModifierToModifierType,
  convertPopulatedModifierGroupToModifierGroupType,
} from '../utils/modifiersConvertUtils';
import { ModifierGroupsClient, PopulatedItemModifierGroupsClient } from './modifierGroupsClient';
import { ModifiersClient } from './modifiersClient';
import { getModifiersIds } from '../utils/modifiersUtils';
import { doesArrayDataExist } from 'root/utils/utils';
import type {
  Modifier,
  ModifierGroup,
  PopulatedModifierGroupTpa,
  ModifierGroupListItem,
} from 'root/types/modifiers';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';
import { getMonitoredApiCall } from './utils/getMonitoredApiCall';

export type ModifiersState = {
  modifiers: Modifier[];
  modifierGroups: ModifierGroup[];
  populatedModifierGroups: ModifierGroupListItem[];
};

export const PopulatedModifiersClient = (
  flowAPI: PlatformControllerFlowAPI,
  isUsePopulatedItemExperimentEnabled: boolean,
  fedops?: FedopsLogger
) => {
  const modifiersState: ModifiersState = {
    modifiers: [],
    modifierGroups: [],
    populatedModifierGroups: [],
  };

  if (isUsePopulatedItemExperimentEnabled) {
    const modifierGroupsClient = new PopulatedItemModifierGroupsClient(flowAPI.httpClient);

    return {
      getAll: async ({ modifierGroupsIds }: { modifierGroupsIds?: string[] }) => {
        let modifierGroupsResponse;

        if (doesArrayDataExist(modifierGroupsIds)) {
          modifierGroupsResponse = await getMonitoredApiCall({
            callback: () => modifierGroupsClient.getAll(modifierGroupsIds),
            fedops: {
              start: fedops?.fetchAllModifierGroupsStarted,
              end: fedops?.fetchAllModifierGroupsEnded,
            },
            reportError: flowAPI.reportError,
          });
        }
        modifiersState.populatedModifierGroups = convertPopulatedModifierGroupToModifierGroupType(
          (modifierGroupsResponse?.data?.data || []) as PopulatedModifierGroupTpa[]
        );
        return modifiersState;
      },
    };
  }
  const modifierGroupsClient = new ModifierGroupsClient(flowAPI.httpClient);
  const modifiersClient = new ModifiersClient(flowAPI.httpClient);

  return {
    getAll: async ({
      priceVariantsIds,
      modifierGroupsIds,
    }: {
      priceVariantsIds?: string[];
      modifierGroupsIds?: string[];
    }) => {
      const modifierIds: string[] = [];
      const variantIds: string[] = [];
      const modifiers: Modifier[] = [];
      const modifierGroups: ModifierGroup[] = [];

      if (doesArrayDataExist(modifierGroupsIds)) {
        const modifierGroupsResponse = await getMonitoredApiCall({
          callback: () => modifierGroupsClient.fetchAllModifierGroups(modifierGroupsIds),
          fedops: {
            start: fedops?.fetchAllModifierGroupsStarted,
            end: fedops?.fetchAllModifierGroupsEnded,
          },
          reportError: flowAPI.reportError,
        });

        modifierGroups.push(
          ...convertModifierGroupToModifierGroupType(
            modifierGroupsResponse.data?.modifierGroups || []
          )
        );

        modifierIds.push(...getModifiersIds(modifierGroups));
      }

      const seenModifierIds: Record<string, boolean> = {};
      const modifierIdsWithoutDuplications = modifierIds.filter((modifierId) => {
        if (seenModifierIds[modifierId]) {
          return false;
        } else {
          seenModifierIds[modifierId] = true;
          return true;
        }
      });

      if (doesArrayDataExist(modifierIdsWithoutDuplications)) {
        const modifiersResponse = await getMonitoredApiCall({
          callback: () => modifiersClient.queryAllModifiers(modifierIdsWithoutDuplications),
          fedops: {
            start: fedops?.fetchAllModifiersStarted,
            end: fedops?.fetchAllModifiersEnded,
          },
          reportError: flowAPI.reportError,
        });
        modifiers.push(
          ...convertModifierToModifierType({
            modifiers: modifiersResponse.data || [],
          })
        );
      }

      if (!isUsePopulatedItemExperimentEnabled) {
        if (doesArrayDataExist(priceVariantsIds)) {
          variantIds.push(...(priceVariantsIds || []));
        }
        const seenVariantIds: Record<string, boolean> = {};
        const variantIdsWithoutDuplications = variantIds.filter((variantId) => {
          // Check if somehow this the variant already included in modifiers
          if (seenModifierIds[variantId] || seenVariantIds[variantId]) {
            return false;
          } else {
            seenVariantIds[variantId] = true;
            return true;
          }
        });
        if (doesArrayDataExist(variantIdsWithoutDuplications)) {
          const modifiersResponse = await getMonitoredApiCall({
            callback: () => modifiersClient.queryAllModifiers(variantIdsWithoutDuplications),
            fedops: {
              start: fedops?.fetchAllModifiersStarted,
              end: fedops?.fetchAllModifiersEnded,
            },
            reportError: flowAPI.reportError,
          });
          modifiers.push(
            ...convertModifierToModifierType({
              modifiers: modifiersResponse.data || [],
            })
          );
        }
      }

      modifiersState.modifiers = modifiers;
      modifiersState.modifierGroups = modifierGroups;
      return modifiersState;
    },
  };
};
