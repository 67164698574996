import type { ModifierListItem } from '../types/modifiers';
import type { ItemPriceVariant, PriceVariant } from '../types/priceVariants';

export const getIdsToPriceVariantsMap = (allPriceVariants: PriceVariant[]) => {
  return allPriceVariants?.reduce((acc, priceVariant) => {
    if (priceVariant.id) {
      acc[priceVariant.id] = priceVariant;
    }
    return acc;
  }, {} as Record<string, PriceVariant>);
};

export const getPriceVariantsWithName = ({
  allPriceVariants,
  priceVariants,
}: {
  allPriceVariants: Record<string, PriceVariant>;
  priceVariants?: ItemPriceVariant[];
}) => {
  return (priceVariants || [])?.reduce((acc, priceVariant) => {
    const selectedPriceVariant = allPriceVariants[priceVariant.id];
    if (selectedPriceVariant) {
      acc = acc.concat({
        id: priceVariant.id,
        name: selectedPriceVariant?.name,
        price: priceVariant.price,
      });
    }
    return acc || [];
  }, [] as ModifierListItem[]);
};

export const getVariantById = ({
  priceVariantsInItem,
  modifiersListItem,
  variantId,
}: {
  priceVariantsInItem?: PriceVariant[];
  modifiersListItem?: ModifierListItem[];
  variantId: string;
}) => {
  if (priceVariantsInItem) {
    return priceVariantsInItem.find((priceVariant) => priceVariant.id === variantId);
  }
  if (modifiersListItem) {
    return modifiersListItem.find((modifierItem) => modifierItem.id === variantId);
  }
};

export const getVariantWithMinimalPrice = <T extends ItemPriceVariant | ModifierListItem>(
  variantListItems: T[]
): T =>
  variantListItems.reduce(
    (prev, curr) => (Number(prev.price) <= Number(curr.price) ? prev : curr),
    {} as T
  );

export const getPriceVariantsIds = (priceVariants?: PriceVariant[]) => {
  return (
    priceVariants?.map((priceVariant) => {
      return priceVariant.id;
    }) ?? []
  );
};
