import type { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  listModifierGroups,
  getModifierGroup,
} from '@wix/ambassador-restaurants-menus-v1-item-modifier-group/http';
import { PopulatedModifierGroupClientFactory } from '@wix/restaurants-populated-item-client/web';

export class ModifierGroupsClient {
  httpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  fetchAllModifierGroups = async (modifierGroupIds?: string[]) => {
    try {
      return this.httpClient
        .request(listModifierGroups({ modifierGroupIds }))
        .then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getModifierGroupById = async (modifierGroupId: string) => {
    try {
      return this.httpClient
        .request(getModifierGroup({ modifierGroupId }))
        .then((response) => response.data);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };
}

export class PopulatedItemModifierGroupsClient {
  httpClient;
  populatedModifiersClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
    this.populatedModifiersClient = PopulatedModifierGroupClientFactory({
      httpClient,
      populationLevel: { modifiers: true },
    });
  }

  getById = async (modifierGroupId: string) => {
    try {
      return this.populatedModifiersClient.getById(modifierGroupId).then((response) => response);
    } catch (e: unknown) {
      console.error(e);
      return {};
    }
  };

  getAll = async (modifierGroupIds?: string[]) => {
    try {
      return this.populatedModifiersClient.getBulk({ ids: modifierGroupIds }).then((response) => {
        if (response.hasError) {
          throw response.error;
        }
        return response?.data;
      });
    } catch (e: unknown) {
      console.error(e);
      return;
    }
  };
}
