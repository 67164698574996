import type { CatalogReferenceModifierGroup } from 'root/services/cartService';
import type { ModifierListItem, SelectedModifiers } from 'root/types/modifiers';

export const getModifierGroupsOptions = (
  selectedModifiers: SelectedModifiers,
  formatCurrency: (price?: number) => string
) => {
  const modifierGroups = Object.keys(selectedModifiers)
    ?.map((modifierGroupId) => {
      return {
        id: modifierGroupId,
        modifiers: selectedModifiers[modifierGroupId]?.map((modifier) => {
          const price = Number(modifier?.price);
          return {
            id: modifier.id,
            formattedPrice: price > 0 ? formatCurrency(price) : undefined,
          };
        }),
      };
    })
    .filter((modifierGroup) => Boolean(modifierGroup.modifiers.length));
  return modifierGroups.length > 0
    ? (modifierGroups as CatalogReferenceModifierGroup[])
    : undefined;
};

export const getModifierListItem = (modifiers: ModifierListItem[], value: string) => {
  return modifiers.find((modifier) => value === modifier.id);
};

export const getFormattedModifierLabel = (
  name: string,
  price: string,
  isAdditionalCharge?: boolean
) => {
  return isAdditionalCharge ? `${name} (+${price})` : name;
};
export const getModifiersOptions = (
  modifiers: ModifierListItem[],
  formatCurrency: (price?: number) => string
) => {
  return modifiers.map((modifier) => {
    const isAdditionalCharge = Number(modifier.price) > 0;
    const formattedPrice = formatCurrency(Number(modifier.price));
    const label = getFormattedModifierLabel(
      modifier.name ?? '',
      formattedPrice,
      isAdditionalCharge
    );
    return {
      label,
      value: modifier.id,
    };
  });
};
