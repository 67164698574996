import type { IWixWindow } from '@wix/yoshi-flow-editor';
import type { Serializer } from 'root/services/serialization/types';

export class WarmupDataManager {
  constructor(private warmupData: IWixWindow['warmupData'], private isSSR: boolean) {
    this.manageData = this.manageData.bind(this);
  }

  async manageData<T>(
    callback: () => Promise<T>,
    staticKey: string,
    dynamicKeySuffix?: Promise<string | undefined>
  ) {
    let res: T | undefined;

    const keySuffix = (await dynamicKeySuffix) ?? '';
    const key = `${staticKey}${keySuffix}`;
    if (!this.isSSR) {
      res = this.warmupData.get(key);
    }
    if (!res) {
      res = await callback();
      if (this.isSSR) {
        this.warmupData.set(key, res);
      }
    }
    return res;
  }

  async manageSerializedData<R, T>(
    callback: () => Promise<T>,
    key: string,
    serializer: Serializer<R, T>
  ) {
    let res: T | undefined;

    if (!this.isSSR) {
      const data = this.warmupData.get(key);
      res = data ? serializer.deserialize(data) : undefined;
    }
    if (!res) {
      res = await callback();
      if (this.isSSR) {
        this.warmupData.set(key, serializer.serialize(res));
      }
    }
    return res;
  }
}
